.sidebar{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #f5f5f5;
    color: black;
    z-index: 998;

   

    .bg{
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        width: 400px;
        background: #92e3a9;
        z-index: 3;


        .links{
            position: absolute;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 20px;

            a{
                font-size: 32px;
            }
        }
    }

    button{ 
        
        width: 50px;
        height: 50px;
        border-radius: 50%;
        position: fixed;
        top: 25px;
        left: 25px;
        background-color: transparent;
        border: none;
        cursor: pointer;
        z-index: 999;
    }

}

@media(max-width:450px) {
    

}

