.contact{
    width: 60vw;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 50px;
    

    .textContainer{
        display: flex;
        flex-direction: column;
        gap: 40px;
        width: 40%;

        h1{
            font-size: 60px;
            line-height: 88px;
            color:#92e3a9;
        }

        .item{
            span{
                a{
                    font-weight: 300;
                    color: #92e3a9;
                    font-size: 20px
                }
               ;
            }
        }

    }

    .formContainer{
        width: 60%;
        position: relative;
        
        .phoneSvg{
            position: absolute;
            margin: auto;
            stroke: #92e3a9;
        }

        form{
            display: flex;
            flex-direction: column;
            gap: 20px;
            z-index: 99;
            position: relative;

            input,textarea{
                padding: 20px;
                background-color: transparent;
                border: 1px solid white;
                color: #92e3a9;
                border-radius: 5px;
                font-size: 16px;
            }

            button{
                padding: 10px;
                border: 1px solid #92e3a9;
                border-radius: 10px;
                color: #92e3a9;
                background-color: transparent;
                cursor: pointer;
                z-index: 1;
                width: 100px;
                font-size: 14px;
            }

            button:hover{
                background-color: #92e3a9;
                color: black;
                border: #0c0c1d;
            }
        }
    }
}

@media(max-width:1306px){
    .contact{
        width: 80%;
        flex-direction: column;
        gap: 25px;
        text-align: center;

        .textContainer{
            justify-content: center;
            align-items: center;
            width: auto;
            h1{
                font-size: 40px;
                line-height: 40px;
            }

            .item{
                span{
                    margin: 2px;
                    font-size: 18px;
                }
            }
        }

        .formContainer{
            width: 90%;

            .phoneSvg{
                svg{
                    width: 80%;
                }
            }

            form{
                input{
                    font-size: 16px;
                }
                textarea{
                    font-size: 16px;
                }
                button{
                    margin: auto;
                }
            }
        }
    }
}