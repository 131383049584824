.hero{
    background: linear-gradient(180deg, #0c0c1d, #111132);
    overflow: hidden;
    position: relative;
    min-height: calc(100vh - 130px);
    display: flex;
    align-items: center;

    .wrapper{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 90%;
        margin: auto;


        .textContainer{
            height: 100%;
            width: 60%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 40px;

            h2{
                font-size: 30px;
                color: #92e3a9;
                letter-spacing: 6px;
            }

            h1{ 
                font-size: 80px;
            }

            .buttons{
                display: flex;
                gap: 10px;
                
                button{
                    padding: 10px;
                    border-radius: 10px;
                    color: #92e3a9;
                    border: 1px solid #92e3a9;
                    background-color: transparent;
                    cursor: pointer;
                    width: 120px;
                    font-size: 16px;
                    z-index: 1;
                }
                
            }

            button:hover{
                background-color: #92e3a9;
                color: black;
                border: #0c0c1d;
            }
        }

        .imageContainer{
            height: 100%;
            width: 40%;
            z-index: 2;
            img{
                height: 100%;
                width: 100%;
                object-fit: contain;
                z-index: 2;
            }
        }
    }

    .slidingText{
        position: absolute;
        font-size: 40vh;
        bottom: -90px;
        left: 50%;
        white-space: nowrap;
        color: #5be4a907;
        letter-spacing: -15px;
        width: 50%;
        font-weight: bold;
        z-index: 0;
    }

}

@media(max-width:450px) {
    .hero{
        
        .wrapper{
           flex-direction: column;
           gap: 100px;
           justify-content: space-between;
           .textContainer{
                h2{
                    font-size: 20px;
                    text-align: center;
                }

                h1{
                    font-size: 40px;
                    text-align: center;
                }

                .buttons{
                    margin: 0 auto;
                    width: auto;
                   flex-direction: column;
                   gap: 20px;
                   
                    button{
                        padding: 10px;
                        font-size: 15px;
                    }
                }
           }


        }

        .slidingText{
            font-size: 25vh;
            bottom: -65px;
            width: 100%;
        }

    }

}