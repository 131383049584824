.projects{
    position: relative;
    width: 90%;
    margin: 0 auto;
    .progress{
        text-align: center;
        color: #92e3a9;
        font-size: 40px; 
        background-color: #0c0c1d;
        z-index: 3;

        .progressBar{
           height: 10px;
           background-color: #92e3a9;
        }
    }

    .container{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        min-height: calc(80vh - 130px);
        overflow: hidden;

        .wrapper{
            max-width: 1366px;
            height: 50%;
            margin: auto;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 50px;

            .imageContainer{
                flex: 1;
                height: 50%;

                img{
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }
    
            .textContainer{
                flex: 1;
                display: flex;
                flex-direction: column;
                gap: 30px;

                h2{
                    font-size: 50px;
                }

                .programs{
                    list-style: none;
                    display: flex;
                    gap: 10px;
                    li{
                        background-color: #92e3a9;
                        color: #0c0c1d;
                        padding: 5px;
                        border-radius: 5px;

                    }
                   
                }

                p{
                    
                    letter-spacing: 0.8px;
                    word-spacing: 1px;
                    font-size: 16px;
                }

                button{
                    padding: 10px;
                    border: 1px solid #92e3a9;
                    border-radius: 10px;
                    color: #92e3a9;
                    background-color: transparent;
                    cursor: pointer;
                    font-size: 16px;
                    z-index: 1;
                    width: 100px;
                }

                button:hover{
                    background-color: #92e3a9;
                    color: black;
                    border: #0c0c1d;
                }
    
            }

            
        }


    }
}

@media(max-width:450px) {
    .projects{
        display: flex;
        flex-direction: column;
        .progress{
            font-size: 27px;

            .progressBar{
                height: 5px;
             }
        }

        .projectContainer{
            display: flex;
            flex-direction: column;
            gap: 80px;
            padding: 50px 0;
            div{
                min-height: auto;
                .container{
                    min-height: auto;
                    .wrapper{
                        flex-direction: column;
                        text-align: center;
                        gap: 20px;
        
                        .imageContainer{
                                object-fit: cover;
                        }
        
                        .textContainer{
                            transform: none !important;
                            h2{
                                font-size: 33px;
                            }
        
                            .programs{
                                justify-content: center;
                               flex-wrap: wrap;
                            }
        
                            button{
                                font-size: 13px;
                                width: 30%;
                                margin: auto;
                            }
                            
                        }
                    }
                }

            } 

        }
    }
}