.parallax{
    width: 100%;
    height: calc(100vh - 100px);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    h1{
        font-size: 100px;
        
    }
    
    .planets{
        background-image: url("../../assets/planets.png");
        background-size: cover;
        background-position: bottom;
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 1;
    }
    
    .mountains{
        background-image: url("../../assets/mountains.png");
        background-size: cover;
        background-position: bottom;
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 2;
    }
    
    .stars{
        background-image: url("../../assets/stars.png");
        background-size: cover;
        background-position: bottom;
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 0;
    }
}

@media(max-width:450px) {
    .parallax{
        h1{
            font-size: 50px;
        }
    }
 
 }

