footer{
    margin-top: auto;
    height: 30px;
    text-align: center;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
}

