.navbar{
    height: 100px;
    width: 90%;
    margin: 0 auto;
    .wrapper{
        max-width: 1366px;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 50px;
        height: 100%;

        span{
            i::before{
                display: block;
                font-size: 25px;
                color: #92e3a9;
            } 
        }
       
    }
    span{
        font-weight: bold;
    }

    .social{
        display: flex;
        gap: 20px;
        
        a{
            width: 25px;
            height: 25px;
        }

        img{
            width: 25px;
            height: 25px;
        }
    }
}

@media(max-width:450px) {
    .navbar{
        .wrapper{
            justify-content: center;
            gap: 25px;
        }
    }

}

