section{

    .aboutContainer{
        width: 60%;
        margin: auto;
    
        h1{
            font-size: 60px;
            color: #92e3a9;
            margin-bottom: 50px;
            text-align: center;
        }
    
        span{
            
            font-size: 13px;
            font-style: italic;
            color: #92e3a9;
            cursor: pointer;
            padding: 20px;
        }

        .textContainer{
            p{
                margin-top: 20px;
                padding: 20px;
                font-size: 17px;
                a{
                    color: #92e3a9;
                }
                span{
                    color: #92e3a9;
                }
                u{
                    color: #92e3a9;
                }
                em{
                    font-size: 12px;
                }
            }
        }

    }
}

@media(max-width:450px){
    section{
        .aboutContainer{
            width: 90%;

            h1{
                font-size: 40px;
            }
        }
    }
}
