@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap');

html{
    scroll-snap-type: y mandatory;
    scroll-behavior: smooth;
}

*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: "Roboto Condensed", sans-serif;
    font-optical-sizing: auto;
}

body{
    background-color: #0c0c1d;
    color: lightgray;
}



a{
    text-decoration: none;
    color: inherit;
}

.App{
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

section{
    min-height: calc(100vh - 130px);
}
