.skills{
    width: 80%;
    margin: 0 auto;
    display: flex;
    justify-content: center;

    flex-wrap: wrap;

    .card{
        width: 30%;
        padding: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;

        h3{
            font-size: 30px;
            padding: 10px 0;
            color: #92e3a9;
        }

        .card-items{
            display: flex;
            gap:10px;
            align-items: center;
            padding: 2px;
            h4{
                font-size: 16px;
                margin: 6px 0;
            }
            
        }
    }
}

@media(max-width:450px) {
    section .skills{
        width: 90%;
        flex-direction: column;

        .card{
            width: 100%;

            h3{
                font-size: 20px;
            }
    
            .card-items{
                h4{
                    font-size: 15px;
                }
            }
        }

        
    }
 
 }
